import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function checkIsRelativePath(path: string): boolean {
  return path.includes('https://maps.googleapis.com');
}

@Injectable()

export class ApiInterceptor implements HttpInterceptor {

  constructor(
    @Inject('BASE_URL') private baseUrl: string
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let clonedReq = req.clone();
    if (!checkIsRelativePath(req.url)) {
      console.log(111);
      clonedReq = req.clone({
        url: this.baseUrl + req.url,
      });
    }
    return next.handle(clonedReq);
  }
}
